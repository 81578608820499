// src/features/subscriptions/SubscribingComponent.js
import React, { useState } from "react";
import { useSubscription, useStompClient } from "react-stomp-hooks";
import { WidgetMap } from "../widgets/types/WidgetTypes";
import { useURLParams } from "../../hooks/useURLParams";

const SubscribingComponent = () => {
    const stompClient = useStompClient();
    const { userId, widgetType } = useURLParams(); // Pobierz `widgetType` z URL
    const [incomingMessage, setIncomingMessage] = useState(null);

    useSubscription("/users/queue/messages", (message) => {
        const parsedMessage = JSON.parse(message.body);

        // Filtruj wiadomości na podstawie `widgetType`
        if (parsedMessage.settings.type !== widgetType) {
            console.warn(
                `Ignored message with type ${parsedMessage.settings.type}, expected ${widgetType}.`
            );
            return; // Ignoruj, jeśli typ widgetu nie pasuje do tego z URL
        }

        setIncomingMessage(parsedMessage);

        // Potwierdź odbiór wiadomości
        stompClient.publish({
            destination: "/app/ack",
            body: JSON.stringify(parsedMessage.paymentId),
        });
    });

    if (!incomingMessage) return <div></div>;

    const WidgetComponent = WidgetMap[incomingMessage.settings.type];
    if (!WidgetComponent) return <div>Unsupported widget type</div>;

    return (
        <WidgetComponent
            settings={incomingMessage.settings}
            data={incomingMessage}
            stompClient={stompClient}
            userId={userId} // Przekazanie userId do widgetu
        />
    );
};

export default SubscribingComponent;
