import { useMemo } from "react";

export const useURLParams = () => {
    return useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const username = params.get("username");
        const widgetType = params.get("widgetType");
        return {
            userId: username, // Zakładamy, że "username" jest właściwie "userId"
            widgetType,
        };
    }, []);
};
