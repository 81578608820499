import { DateTime } from "luxon";

/**
 * Odtwarza dźwięk wygenerowany z tekstu wiadomości.
 * @param {string} paymentId - Identyfikator płatności.
 * @param {Function} onEndCallback - Funkcja wywoływana po zakończeniu odtwarzania.
 * @param {Object} stompClient - Klient STOMP do komunikacji WebSocket.
 */
export const playAudio = async (paymentId, onEndCallback, stompClient) => {
    if (!paymentId || !stompClient) {
        console.error("Invalid parameters for playAudio.");
        return;
    }

    try {
        const audioContext = new AudioContext();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/payments/${paymentId}/text-to-speech`);

        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
            if (response.status === 500) {
                sendNack(paymentId, stompClient);
            }
            throw new Error(`Failed to fetch audio for paymentId: ${paymentId}`);
        }

        const buffer = await response.arrayBuffer();
        const decodedData = await audioContext.decodeAudioData(buffer);
        const source = audioContext.createBufferSource();
        source.buffer = decodedData;
        source.connect(audioContext.destination);
        source.start(0);

        // Callback po zakończeniu odtwarzania
        source.onended = () => {
            if (onEndCallback) {
                onEndCallback({ paymentId, stompClient });
            }
        };
    } catch (error) {
        console.error("Error playing audio:", error);
    }
};

/**
 * Wysyła wiadomość NACK w przypadku problemów z odtwarzaniem.
 * @param {string} paymentId - Identyfikator płatności.
 * @param {Object} stompClient - Klient STOMP do komunikacji WebSocket.
 */
const sendNack = (paymentId, stompClient) => {
    if (!paymentId || !stompClient) {
        console.error("Invalid parameters for sendNack.");
        return;
    }

    const nackMessage = {
        paymentId,
        status: "NACK",
        timestamp: DateTime.utc().toISO(),
    };

    stompClient.publish({
        destination: "/app/nack",
        body: JSON.stringify(nackMessage),
    });

    console.log(`NACK message sent for paymentId: ${paymentId}`);
};

export const onEndSound = ({ userId, paymentId, stompClient, setVisible }) => {
    if (!paymentId || !stompClient || !userId) {
        console.error("Invalid parameters for onEndSound.");
        return;
    }

    console.log("Speech synthesis completed for paymentId:", paymentId);
    setVisible(false);

    const confirmationMessage = {
        paymentId,
        userId, // Poprawione, aby używać właściwego userId
        shownAt: DateTime.utc().toISO(),
    };

    stompClient.publish({
        destination: "/app/confirmation",
        body: JSON.stringify(confirmationMessage),
    });

    console.log(`Confirmation sent for paymentId: ${paymentId}`);
};
