// src/features/widgets/components/TextWidget.js
import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {onEndSound, playAudio} from "../utils/audioUtils";
import {getStyle} from "../../../common/utils/styleUtils";



const TextWidget = ({ settings, data, stompClient, userId }) => {
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        const handleAudioPlayback = async () => {
            try {
                await playAudio(data.paymentId, () => {
                    onEndSound({
                        userId, // Przekazanie właściwego userId
                        paymentId: data.paymentId,
                        stompClient,
                        setVisible,
                    });
                }, stompClient);
                setVisible(true);
            } catch (error) {
                console.error("Error during audio playback:", error);
            }
        };

        handleAudioPlayback();
    }, [data.paymentId, userId, stompClient]);

    if (!isVisible) return null;

    return (
        <Animated
            animationIn="lightSpeedIn"
            animationOut="zoomOutDown"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={isVisible}
        >
            <div>
                <span style={getStyle(settings, "header")}>
                    {data.name} wpłacił {data.amount} zł
                </span>
            </div>
            <div>
                <span style={getStyle(settings, "message")}>
                    {data.message}
                </span>
            </div>
        </Animated>
    );
};

export default TextWidget;
